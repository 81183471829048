const  EnvName = "demo";
let key = {};
let IPFS_IMG = " https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
export const ENCODEKEY = process.env.REACT_APP_ENCODEKEY
console.log("config_ENCODEKEY",ENCODEKEY, process.env);
var Front_market_Url = ''
var BackEndUrl= ''

if (EnvName === "local") {
  Back_Url = "http://localhost:3331/v1/admin";
   BackEndUrl = 'http://localhost:3331/v1';

  Front_market_Url = "https://vertendi.maticz.in/";
  var image_url = "hhttp://localhost:3331/token";
  ImG = "http://localhost:3331";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'

  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://localhost:3331/v1";
}
if (EnvName === "demo") {
  Back_Url = "https://backend-vertendi.maticz.in/v1/admin";
   BackEndUrl = 'https://backend-vertendi.maticz.in/v1';

  Front_market_Url = "https://vertendi.maticz.in/";
  var image_url = "https://backend-vertendi.maticz.in/token";
  ImG = "https://backend-vertendi.maticz.in";
  var Back_Url_Token = "https://backend-vertendi.maticz.in/v1";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
 
}



if (EnvName === "naiftydemo") {
  BackEndUrl = 'http://192.53.121.26:7017/v1';

  Back_Url = "http://192.53.121.26:7017/v1/admin";
  Front_market_Url = "http://nftdemo.bimaticz.com/naifty";
  var image_url = "http://192.53.121.26:2525/token";
  ImG = "http://192.53.121.26:7017";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'

  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://192.53.121.26:7017/v1";
}

if (EnvName === "production") {
  FRONT_URL = "https://contorls-ytrb.naifty.io";
  Front_market_Url = 'https://naifty.io'
  Back_Url = 'https://api.naifty.io/v1/admin'
  var image_url = 'https://api.naifty.io'
  ImG ='https://api.naifty.io';
  var tradeAddress = "0xC9Cfa2dd1Ed7Bc29C7d9B990b2DE7Ee630478ec4";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "1";
  var ETHCHAIN = 1
  var BNBCHAIN = 56
  var BNBCHAIN_NAME = 'MATIC'

  var chainlist = [56,1]
  var chainId = "0x1";
  var BNBversion = "0x38"
  var ETHversion = "0x1"
  var versions =["0x38","0x1"]
  var ETHprovider = "https://mainnet.infura.io/v3/8bd0c393365f4c559c7ca6179a12fea6";
  var BNBprovider = "https://bsc-dataseed.binance.org/";
  var Back_Url_Token = "https://naifty.io/v1";
}

key = {
   BNBCHAIN_NAME : BNBCHAIN_NAME , 
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  ETHprovider : ETHprovider,
  ETHCHAIN : ETHCHAIN,
  BNBCHAIN : BNBCHAIN,
  chainlist : chainlist,
  bnbver : BNBversion,
  ethver : ETHversion,
  versionlist :versions ,
  ENVname : EnvName,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  ETHTRADE : ETHTRADE,
  BNBTRADE : BNBTRADE,
  AdminAddress: "0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion , 
  BackEndUrl :`${BackEndUrl}` 
   
};

export default key;
